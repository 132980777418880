





















































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, SetupContext } from '@vue/composition-api';
import { CarouselBanner } from '@/types/tsv-config';
import TopConfigsService from '@/logic/tsv/top-configs.service';
import HighPricePd from '@/components/high-price-pd.vue';
import FourReasons from '@/components/four-reasons.vue';
import PublicRelations from '@/components/pr.vue';
import ThreeSellingWays from '@/components/three-selling-ways.vue';
import SellingStore from '@/components/selling-store.vue';
import SellingDelivery from '@/components/selling-delivery.vue';
import SellingVisit from '@/components/selling-visit.vue';
import QuickAssessment from '@/components/quick-assessment.vue';
import SearchKeyword from '@/components/search-keyword.vue';
import SellingPd from '@/components/selling-pd.vue';
import NewsService from '@/logic/news.service';
import { NewsOnScreen } from '@/types/news';
import { getNewsLinkUrl, isDebugMode } from '@/logic/utils';
import PurchaseApplicationButton from '@/components/common/purchase-application-button.vue';
import TelephoneApplication from '@/components/telephone-application.vue';
import SimpleQuote from '@/components/simple-quote.vue';

export default Vue.extend({
  name: 'top-page',
  components: {
    'high-price-pd': HighPricePd,
    'public-relations': PublicRelations,
    'four-reasons': FourReasons,
    'three-selling-ways': ThreeSellingWays,
    'selling-store': SellingStore,
    'selling-delivery': SellingDelivery,
    'selling-visit': SellingVisit,
    'quick-assessment': QuickAssessment,
    'search-keyword': SearchKeyword,
    'selling-pd': SellingPd,
    'purchase-application-button': PurchaseApplicationButton,
    'telephone-application': TelephoneApplication,
    'simple-quote': SimpleQuote
  },
  metaInfo: () => {
    return {
      title: 'カメラのキタムラ ネット中古 | 中古カメラ・中古レンズの販売・買取'
    };
  },
  setup: (prop, context: SetupContext) => {
    const { searcher } = context.root.$store;
    const state = reactive({
      imageBaseUrl: process.env.VUE_APP_IMAGE_NOT_BANNER_OTHER_BASE_URL,
      model: null,
      selectedCamera: null,
      selectedAppliance: null,
      carouselBannerList: [] as CarouselBanner[],
      announcements: {} as NewsOnScreen,
      showAnnouncements: false,
      newOldList: ['新品・中古', '新品のみ', '中古のみ', '買収'],
      selectNewOld: '新品・中古',
      categoryList: ['全てのカテゴリから', 'コンパクトデジタルカメラ'],
      selectCategory: '全てのカテゴリから',
      searchWord: searcher.searchWord,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      search: null,
      items: [{ text: 'キヤノン' }, { text: 'カシオ' }, { text: 'ソニー' }],
      bannerList: [
        {
          src: 'https://www.kitamura.jp/carousel/__icsFiles/thumbnail/2020/12/25/carousel_shoplesson_cp202101.png'
        },
        {
          src: 'https://www.kitamura.jp/carousel/__icsFiles/thumbnail/2020/12/25/carousel_shoplesson_cp202101.png'
        }
      ],
      cameraList: [
        { text: '交換レンズ', url: '' },
        { text: 'ミラーレス一眼', url: '' },
        { text: 'デジタル一眼レフ', url: '' },
        { text: 'コンパクトデジタルカメラ', url: '' },
        { text: '交換レンズアクセサリー', url: '' },
        { text: '三脚', url: '' }
      ],
      AppliancesList: [
        { text: '空調家電', url: '' },
        { text: '生活/家事家電', url: '' },
        { text: 'キッチン/調理家電', url: '' }
      ]
    });

    // カルーセルバナーを取得
    const tsvPlus = isDebugMode() ? parseInt((context.root.$route.query.tsv_plus as string) || '0') : 0;
    TopConfigsService.fetchCarouselBanners(tsvPlus).then((carouselBanners) => {
      state.carouselBannerList = carouselBanners;
    });
    const getLinkTarget = (isBlank: 'yes' | 'no') => {
      return isBlank === 'yes' ? '_blank' : '_self';
    };

    // お知らせ一覧を取得する
    NewsService.searchNews(false, {
      limit: 1,
      newsType: [4]
    }).then((newsResult) => {
      if (!newsResult || newsResult.length === 0) {
        return;
      }
      // 値があるとき表示
      state.showAnnouncements = true;

      /**
       * 表示に使う変数のみ抜き出す
       * ニュースAPIのレスポンスで遷移先URLが指定なしの場合は、
       * デフォルトで/news/display.html?id=＜お知らせ番号＞をリンク先に指定する
       */
      state.announcements = {
        newsDate: new Date(newsResult[0].newsDate),
        newsType: 'お知らせ',
        newsTitle: newsResult[0].newsTitle,
        linkTab: newsResult[0].linkTab,
        linkUrl: getNewsLinkUrl(newsResult[0])
      };
    });

    /**
     * お知らせのリンクを開く際のタブの挙動方法を取得する
     * 1：別タブにする 2：同一タブ内
     */
    function getAnnouncementsLinkTarget(linkTab: 1 | 2) {
      return linkTab === 2 ? '_self' : '_blank';
    }

    return {
      ...toRefs(state),
      getLinkTarget,
      getAnnouncementsLinkTarget
    };
  }
});
