



































































































































































































import Vue from 'vue';
import SyuttyouKaitoriNews from './syuttyou-kaitori-news.vue';
import SyuttyouKaitoriArea from './syuttyou-kaitori-area.vue';
import SyuttyouKaitoriPoint from './syuttyou-kaitori-point.vue';
import SyuttyouKaitoriVoice from './syuttyou-kaitori-voice.vue';
import SyuttyouKaitoriItem from './syuttyou-kaitori-item.vue';
import SyuttyouKaitoriStep from './syuttyou-kaitori-step.vue';
import SyuttyouKaitoriCta from './syuttyou-kaitori-cta.vue';
import HoujinKaitoriBanner from '@/components/page/service/houjin-kaitori/houjin-kaitori-banner.vue';
import PrHosoku from '@/components/page/common/pr-hosoku.vue';

export default Vue.extend({
  name: 'syuttyou-kaitori-top',
  components: {
    'syuttyou-kaitori-news': SyuttyouKaitoriNews,
    'syuttyou-kaitori-area': SyuttyouKaitoriArea,
    'syuttyou-kaitori-point': SyuttyouKaitoriPoint,
    'syuttyou-kaitori-voice': SyuttyouKaitoriVoice,
    'syuttyou-kaitori-item': SyuttyouKaitoriItem,
    'syuttyou-kaitori-step': SyuttyouKaitoriStep,
    'syuttyou-kaitori-cta': SyuttyouKaitoriCta,
    'houjin-kaitori-banner': HoujinKaitoriBanner,
    'pr-hosoku': PrHosoku
  },
  data() {
    return {
      syuttyouKaitoriSignboard: {
      }
    };
  },
});
