var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-banner-area",class:{
      'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'container-narrow': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"id":"main-banner"}},[_c('div',{class:{
        'banner-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'banner-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
      }},[(_vm.showAnnouncements)?_c('div',{class:{
          'attention-area': true,
          'attention-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'attention-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('i',{staticClass:"fas fa-caret-right red-icon"}),_c('div',{staticClass:"attention-text-area"},[_c('a',{attrs:{"href":_vm.announcements.linkUrl,"target":_vm.getAnnouncementsLinkTarget(_vm.announcements.linkTab)},domProps:{"innerHTML":_vm._s(_vm.announcements.newsTitle)}})]),_c('i',{staticClass:"fas fa-times-circle cancel",on:{"click":function($event){_vm.showAnnouncements = false}}})]):_vm._e()]),_c('div',{class:{
        'banner-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'banner-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
      }},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"carousel-placeholder"},[_c('v-carousel',{attrs:{"height":"auto","hide-delimiters":"","interval":"2500","cycle":""}},_vm._l((_vm.carouselBannerList),function(item,i){return _c('a',{key:("pc-carousel-banner-" + i),attrs:{"href":item.linkUrl,"target":_vm.getLinkTarget(item.isBlank)}},[_c('v-carousel-item',{key:i,attrs:{"eager":""}},[_c('v-img',{attrs:{"src":item.imageSrc,"height":"100%","eager":""}})],1)],1)}),0)],1):_c('div',{staticClass:"carousel-placeholder-narrow"},[_c('v-carousel',{attrs:{"height":"auto","hide-delimiters":"","interval":"2500","cycle":"","show-arrows":!_vm.$vuetify.breakpoint.xs}},_vm._l((_vm.carouselBannerList),function(item,i){return _c('a',{key:("sp-carousel-banner-" + i),attrs:{"href":item.linkUrl,"target":_vm.getLinkTarget(item.isBlank)}},[_c('v-carousel-item',{key:i,attrs:{"eager":""}},[_c('v-img',{attrs:{"src":item.imageSpSrc,"height":"100%","eager":""}})],1)],1)}),0)],1)])]),_c('div',{staticClass:"main-content-area",class:{
      'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'container-narrow': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"id":"main-content"}},[_c('div',{staticClass:"ec-back-color",class:{
        'content-area-outer-wide mt-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow full-width mt-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"high-price-pd"}},[_c('div',{class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('high-price-pd')],1)]),_c('div',{class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"pd"}},[_c('div',{class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('public-relations')],1)]),_c('div',{staticClass:"ec-back-color",class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"simple-quote"}},[_c('div',{staticClass:"ec-back-color",class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('span',{staticClass:"content-title",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          }},[_vm._v("ネットでかんたん見積り")]),_c('simple-quote')],1)]),_c('div',{class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"four-reasons"}},[_c('div',{class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('span',{staticClass:"content-title",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          }},[_vm._v("当店が選ばれる4つの理由")]),_c('four-reasons')],1)]),_c('div',{staticClass:"ec-back-color",class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"three-selling-ways"}},[_c('div',{staticClass:"ec-back-color",class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('span',{staticClass:"content-title",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          }},[_vm._v("3つの買取り方法")]),_c('three-selling-ways',{attrs:{"is-top":true}})],1)]),_c('div',{class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"selling-store"}},[_c('div',{staticClass:"ec-goto-link",attrs:{"id":"selling-store-link"}}),_c('div',{class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('span',{staticClass:"content-title",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          }},[_vm._v("店舗で買取り")]),_c('selling-store')],1)]),_c('div',{staticClass:"ec-back-color",class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"selling-delivery"}},[_c('div',{staticClass:"ec-goto-link",attrs:{"id":"selling-delivery-link"}}),_c('div',{staticClass:"ec-back-color",class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('span',{staticClass:"content-title",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          }},[_vm._v("宅配で買取り")]),_c('selling-delivery')],1)]),_c('div',{class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"selling-visit"}},[_c('div',{staticClass:"ec-goto-link",attrs:{"id":"selling-visit-link"}}),_c('div',{class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('span',{staticClass:"content-title",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          }},[_vm._v("出張で買取り")]),_c('selling-visit')],1)]),_c('div',{staticClass:"ec-back-color",class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      }},[_c('div',{staticClass:"ec-goto-link",attrs:{"id":"quick-assessment-link"}}),_c('div',{staticClass:"ec-back-color",class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        },attrs:{"name":"quick-assessment"}},[_c('span',{staticClass:"title-small",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          }},[_vm._v("60秒で簡単入力")]),_c('span',{staticClass:"content-title",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          }},[_vm._v("無料クイック査定")]),_c('quick-assessment')],1)]),_c('div',{class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"search-keyword"}},[_c('div',{staticClass:"content-area",class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('search-keyword')],1)]),_c('div',{class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"selling-pd"}},[_c('div',{class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('span',{staticClass:"content-title",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          }},[_vm._v("買取り対象商品")]),_c('selling-pd')],1)]),_c('div',{staticClass:"ec-back-color",class:{
        'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"name":"special-feature"}},[_c('div',{staticClass:"ec-back-color",class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('div',{staticClass:"featur-artic"},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-card',{attrs:{"color":"#fff","href":"/ec/page/feature/recommended-old-lens","elevation":"0"}},[_c('v-img',{attrs:{"src":"/ec/images2/top/recommended-old-lens-400x400.jpg"}}),_c('v-card-text',[_vm._v(" 今からはじめるオールドレンズ！基本とおすすめのレンズ ")])],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-card',{attrs:{"color":"#fff","href":"/ec/page/feature/tell-me-35mm-film","elevation":"0"}},[_c('v-img',{attrs:{"src":"/ec/images2/top/recommended-35mm-film-400x400.jpg"}}),_c('v-card-text',[_vm._v(" 今こそ使いたい！おすすめフィルムカメラ 35mmフィルムカメラ編 ")])],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-card',{attrs:{"color":"#fff","href":"/ec/page/feature/tell-me-chuuban","elevation":"0"}},[_c('v-img',{attrs:{"src":"/ec/images2/top/recommended-medium-format-film-400x400.jpg"}}),_c('v-card-text',[_vm._v(" 今こそ使いたい！おすすめフィルムカメラ 中判フィルムカメラ編 ")])],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-card',{attrs:{"color":"#fff","href":"/ec/page/feature/film-purchase","elevation":"0"}},[_c('v-img',{attrs:{"src":"/ec/images2/top/film-kaitori-400x400.jpg"}}),_c('v-card-text',[_vm._v(" アナログ感が新鮮で再ブーム! フィルムカメラ買取り強化 ")])],1)],1)],1)],1)])])]),_c('div',{staticClass:"ec-back-telephone",class:{
        'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
      }},[_c('div',{class:{
          'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('span',{staticClass:"content-title",class:{
            'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
            'content-title-small': _vm.$vuetify.breakpoint.smAndDown
          },staticStyle:{"position":"relative","z-index":"1"}},[_vm._v("お問い合わせ総合窓口")]),_c('telephone-application',{attrs:{"is-top":true}})],1)])]),_c('purchase-application-button')],1)}
var staticRenderFns = []

export { render, staticRenderFns }