import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';
import VueGtm from 'vue-gtm';
import { isDebugMode } from '@/logic/utils';
import GtmService from '@/logic/gtm.service';
import MyPageRoutes from './mypage-routes';

import TopPage from '../views/top-page.vue';
import SellTopPage from '../views/sell/sell-top-page.vue';
import SellListPage from '../views/sell/sell-list-page.vue';
import SellDetailPage from '../views/sell/sell-detail-page.vue';
import SellPricePage from '../views/sell/sell-price-page.vue';
import SellSelectPage from '../views/sell/sell-select-page.vue';
import TempMitsumoriPage from '../views/temp-mitsumori-page.vue';

// 遅延ローディング
/* webpackChunkName: "MyPage" 
const MyPage = () => import('../views/my-page.vue');
*/
/* webpackChunkName: "HtmlPage" 
const HtmlPage = () => import('../views/static/html-page.vue');
*/
/* webpackChunkName: "NotFoundPage"
const NotFoundPage = () => import('../views/not-found-page.vue');
*/

// 遅延ローディング[NewsPage] webpackChunkName: "NewsPage"
/*
const NewsListPage = () => import('../views/news/news-list-page.vue');
const NewsDetailPage = () => import('../views/news/news-detail-page.vue');
*/

// 遅延ローディング[SyuttyouKaitori] webpackChunkName: "SyuttyouKaitori"
/*
const SyuttyouKaitoriHokkaido = () => import('../views/page/service/syuttyou-kaitori/hokkaido.vue');
const SyuttyouKaitoriMiyagi = () => import('../views/page/service/syuttyou-kaitori/miyagi.vue');
const SyuttyouKaitoriTokyo = () => import('../views/page/service/syuttyou-kaitori/tokyo.vue');
const SyuttyouKaitoriKanagawa = () => import('../views/page/service/syuttyou-kaitori/kanagawa.vue');
const SyuttyouKaitoriChiba = () => import('../views/page/service/syuttyou-kaitori/chiba.vue');
const SyuttyouKaitoriSaitama = () => import('../views/page/service/syuttyou-kaitori/saitama.vue');
const SyuttyouKaitoriIbaraki = () => import('../views/page/service/syuttyou-kaitori/ibaraki.vue');
const SyuttyouKaitoriTochigi = () => import('../views/page/service/syuttyou-kaitori/tochigi.vue');
const SyuttyouKaitoriGunma = () => import('../views/page/service/syuttyou-kaitori/gunma.vue');
const SyuttyouKaitoriAichi = () => import('../views/page/service/syuttyou-kaitori/aichi.vue');
const SyuttyouKaitoriShizuoka = () => import('../views/page/service/syuttyou-kaitori/shizuoka.vue');
const SyuttyouKaitoriOsaka = () => import('../views/page/service/syuttyou-kaitori/osaka.vue');
const SyuttyouKaitoriKyoto = () => import('../views/page/service/syuttyou-kaitori/kyoto.vue');
const SyuttyouKaitoriHyogo = () => import('../views/page/service/syuttyou-kaitori/hyogo.vue');
const SyuttyouKaitoriNara = () => import('../views/page/service/syuttyou-kaitori/nara.vue');
const SyuttyouKaitoriShiga = () => import('../views/page/service/syuttyou-kaitori/shiga.vue');
const SyuttyouKaitoriWakayama = () => import('../views/page/service/syuttyou-kaitori/wakayama.vue');
const SyuttyouKaitoriKagawa = () => import('../views/page/service/syuttyou-kaitori/kagawa.vue');
const SyuttyouKaitoriFukuoka = () => import('../views/page/service/syuttyou-kaitori/fukuoka.vue');
*/

import HtmlPage from '../views/static/html-page.vue';
import NewsListPage from '../views/news/news-list-page.vue';
import NewsDetailPage from '../views/news/news-detail-page.vue';
import MyPage from '../views/my-page.vue';
import NotFoundPage from '../views/not-found-page.vue';
import SyuttyouKaitoriHokkaido from '../views/page/service/syuttyou-kaitori/hokkaido.vue';
import SyuttyouKaitoriMiyagi from '../views/page/service/syuttyou-kaitori/miyagi.vue';
import SyuttyouKaitoriIbaraki from '../views/page/service/syuttyou-kaitori/ibaraki.vue';
import SyuttyouKaitoriTochigi from '../views/page/service/syuttyou-kaitori/tochigi.vue';
import SyuttyouKaitoriGunma from '../views/page/service/syuttyou-kaitori/gunma.vue';
import SyuttyouKaitoriSaitama from '../views/page/service/syuttyou-kaitori/saitama.vue';
import SyuttyouKaitoriChiba from '../views/page/service/syuttyou-kaitori/chiba.vue';
import SyuttyouKaitoriTokyo from '../views/page/service/syuttyou-kaitori/tokyo.vue';
import SyuttyouKaitoriKanagawa from '../views/page/service/syuttyou-kaitori/kanagawa.vue';
import SyuttyouKaitoriNiigata from '../views/page/service/syuttyou-kaitori/niigata.vue';
import SyuttyouKaitoriToyama from '../views/page/service/syuttyou-kaitori/toyama.vue';
import SyuttyouKaitoriYamanashi from '../views/page/service/syuttyou-kaitori/yamanashi.vue';
import SyuttyouKaitoriShizuoka from '../views/page/service/syuttyou-kaitori/shizuoka.vue';
import SyuttyouKaitoriAichi from '../views/page/service/syuttyou-kaitori/aichi.vue';
import SyuttyouKaitoriShiga from '../views/page/service/syuttyou-kaitori/shiga.vue';
import SyuttyouKaitoriKyoto from '../views/page/service/syuttyou-kaitori/kyoto.vue';
import SyuttyouKaitoriOsaka from '../views/page/service/syuttyou-kaitori/osaka.vue';
import SyuttyouKaitoriHyogo from '../views/page/service/syuttyou-kaitori/hyogo.vue';
import SyuttyouKaitoriNara from '../views/page/service/syuttyou-kaitori/nara.vue';
import SyuttyouKaitoriWakayama from '../views/page/service/syuttyou-kaitori/wakayama.vue';
import SyuttyouKaitoriOkayama from '../views/page/service/syuttyou-kaitori/okayama.vue';
import SyuttyouKaitoriTokushima from '../views/page/service/syuttyou-kaitori/tokushima.vue';
import SyuttyouKaitoriKagawa from '../views/page/service/syuttyou-kaitori/kagawa.vue';
import SyuttyouKaitoriEhime from '../views/page/service/syuttyou-kaitori/ehime.vue';
import SyuttyouKaitorikochi from '../views/page/service/syuttyou-kaitori/kochi.vue';
import SyuttyouKaitoriFukuoka from '../views/page/service/syuttyou-kaitori/fukuoka.vue';
import SyuttyouKaitoriSaga from '../views/page/service/syuttyou-kaitori/saga.vue';
import SyuttyouKaitoriNagasaki from '../views/page/service/syuttyou-kaitori/nagasaki.vue';
import SyuttyouKaitoriKumamoto from '../views/page/service/syuttyou-kaitori/kumamoto.vue';
import GuideSitemap from '../views/page/guide/sitemap.vue';
import GuideServiceGuide from '../views/page/guide/service-guide.vue';
import GuideAsk from '../views/page/guide/ask.vue';
import GuideKiyaku from '../views/page/guide/kiyaku.vue';
import ServiceUsed6monthsWarranty from '../views/page/service/used-6months-warranty.vue';
import ServiceUsedSp90daysWarranty from '../views/page/service/used-sp-90days-warranty.vue';
import ServiceUsedWatch1yearsWarranty from '../views/page/service/used-watch-1years-warranty.vue';
import ServiceSellGuide from '../views/page/service/sell-guide.vue';
import ServiceSyuttyouKaitori from '../views/page/service/syuttyou-kaitori.vue';
import ServiceSyuttyouKaitoriAd from '../views/page/service/syuttyou-kaitori_ad.vue';
import ServiceSyuttyouKaitoriAd2 from '../views/page/service/syuttyou-kaitori_ad2.vue';
import ServiceSyuttyouKaitoriAd3 from '../views/page/service/syuttyou-kaitori_ad3.vue';
import ServiceSyuttyouKaitoriAd4 from '../views/page/service/syuttyou-kaitori_ad4.vue';
import ServiceTyokusouKaitori from '../views/page/service/tyokusou-kaitori.vue';
import ServiceTokutokuKaitori from '../views/page/service/tokutoku-kaitori.vue';
import ServiceHoujinKaitori from '../views/page/service/houjin-kaitori.vue';
import News20200601 from '../views/page/news/20200601.vue';
import FeatureFilmPurchase from '../views/page/feature/film-purchase.vue';
import FeatureCameraGuide from '../views/page/feature/camera-guide.vue';
import FeatureRecommendedOldLens from '../views/page/feature/recommended-old-lens.vue';
import FeatureTellMe35mmFilm from '../views/page/feature/tell-me-35mm-film.vue';
import FeatureTellMeChuuban from '../views/page/feature/tell-me-chuuban.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'top-page',
    meta: { gtm: 'TopPage' },
    component: TopPage
  },
  {
    path: '/ec/sell',
    name: 'sell-top-page',
    meta: { gtm: 'SellTopPage' },
    component: SellTopPage
  },
  {
    path: '/ec/sell/item-list',
    name: 'sell-list-page',
    meta: { gtm: 'SellListPage' },
    component: SellListPage
  },
  {
    path: '/ec/sell/temp-mitsumori',
    name: 'temp-mitsumori-page',
    meta: { gtm: 'TempMitsumoriPage' },
    component: TempMitsumoriPage
  },
  {
    path: '/ec/sell/item-price',
    name: 'sell-price-page',
    meta: { gtm: 'SellPricePage' },
    component: SellPricePage
  },
  {
    path: '/ec/sell/item-select',
    name: 'sell-select-page',
    meta: { gtm: 'SellSelectPage' },
    component: SellSelectPage
  },
  {
    path: '/ec/sell/:id',
    name: 'sell-detail-page',
    meta: { gtm: 'SellDetailPage' },
    component: SellDetailPage
  },
  {
    path: '/ec/campaign/:page',
    name: 'html-page',
    meta: { gtm: 'CampaignPage' },
    component: HtmlPage
  },
  {
    path: '/ec/guide/:page',
    name: 'html-page',
    meta: { gtm: 'GuidePage' },
    component: HtmlPage
  },
  {
    path: '/ec/other-news/:page',
    name: 'html-page',
    meta: { gtm: 'OtherNewsPage' },
    component: HtmlPage
  },
  {
    path: '/ec/service/:page',
    name: 'html-page',
    meta: { gtm: 'ServicePage' },
    component: HtmlPage
  },
  {
    path: '/ec/news',
    name: 'news-list-page',
    meta: { gtm: 'NewsListPage' },
    component: NewsListPage
  },
  {
    path: '/ec/news/:year',
    name: 'news-list-year-page',
    meta: { gtm: 'NewsListPage' },
    component: NewsListPage
  },
  {
    path: '/ec/news/detail/:id',
    name: 'news-detail-page',
    meta: { gtm: 'NewsDetailPage' },
    component: NewsDetailPage
  },
  {
    path: '/ec/mypage',
    component: MyPage,
    children: [...MyPageRoutes]
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/hokkaido',
    name: 'syuttyou-kaitori-hokkaido',
    meta: { gtm: 'syuttyou-kaitori-hokkaido' },
    component: SyuttyouKaitoriHokkaido
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/miyagi',
    name: 'syuttyou-kaitori-miyagi',
    meta: { gtm: 'syuttyou-kaitori-miyagi' },
    component: SyuttyouKaitoriMiyagi
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/ibaraki',
    name: 'syuttyou-kaitori-ibaraki',
    meta: { gtm: 'syuttyou-kaitori-ibaraki' },
    component: SyuttyouKaitoriIbaraki
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/tochigi',
    name: 'syuttyou-kaitori-tochigi',
    meta: { gtm: 'syuttyou-kaitori-tochigi' },
    component: SyuttyouKaitoriTochigi
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/gunma',
    name: 'syuttyou-kaitori-gunma',
    meta: { gtm: 'syuttyou-kaitori-gunma' },
    component: SyuttyouKaitoriGunma
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/saitama',
    name: 'syuttyou-kaitori-saitama',
    meta: { gtm: 'syuttyou-kaitori-saitama' },
    component: SyuttyouKaitoriSaitama
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/chiba',
    name: 'syuttyou-kaitori-chiba',
    meta: { gtm: 'syuttyou-kaitori-chiba' },
    component: SyuttyouKaitoriChiba
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/tokyo',
    name: 'syuttyou-kaitori-tokyo',
    meta: { gtm: 'syuttyou-kaitori-tokyo' },
    component: SyuttyouKaitoriTokyo
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/kanagawa',
    name: 'syuttyou-kaitori-kanagawa',
    meta: { gtm: 'syuttyou-kaitori-kanagawa' },
    component: SyuttyouKaitoriKanagawa
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/niigata',
    name: 'syuttyou-kaitori-niigata',
    meta: { gtm: 'syuttyou-kaitori-niigata' },
    component: SyuttyouKaitoriNiigata
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/toyama',
    name: 'syuttyou-kaitori-toyama',
    meta: { gtm: 'syuttyou-kaitori-toyama' },
    component: SyuttyouKaitoriToyama
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/yamanashi',
    name: 'syuttyou-kaitori-yamanashi',
    meta: { gtm: 'syuttyou-kaitori-yamanashi' },
    component: SyuttyouKaitoriYamanashi
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/shizuoka',
    name: 'syuttyou-kaitori-shizuoka',
    meta: { gtm: 'syuttyou-kaitori-shizuoka' },
    component: SyuttyouKaitoriShizuoka
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/aichi',
    name: 'syuttyou-kaitori-aichi',
    meta: { gtm: 'syuttyou-kaitori-aichi' },
    component: SyuttyouKaitoriAichi
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/shiga',
    name: 'syuttyou-kaitori-shiga',
    meta: { gtm: 'syuttyou-kaitori-shiga' },
    component: SyuttyouKaitoriShiga
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/kyoto',
    name: 'syuttyou-kaitori-kyoto',
    meta: { gtm: 'syuttyou-kaitori-kyoto' },
    component: SyuttyouKaitoriKyoto
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/osaka',
    name: 'syuttyou-kaitori-osaka',
    meta: { gtm: 'syuttyou-kaitori-osaka' },
    component: SyuttyouKaitoriOsaka
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/hyogo',
    name: 'syuttyou-kaitori-hyogo',
    meta: { gtm: 'syuttyou-kaitori-hyogo' },
    component: SyuttyouKaitoriHyogo
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/nara',
    name: 'syuttyou-kaitori-nara',
    meta: { gtm: 'syuttyou-kaitori-nara' },
    component: SyuttyouKaitoriNara
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/wakayama',
    name: 'syuttyou-kaitori-wakayama',
    meta: { gtm: 'syuttyou-kaitori-wakayama' },
    component: SyuttyouKaitoriWakayama
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/tokushima',
    name: 'syuttyou-kaitori-tokushima',
    meta: { gtm: 'syuttyou-kaitori-tokushima' },
    component: SyuttyouKaitoriTokushima
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/kagawa',
    name: 'syuttyou-kaitori-kagawa',
    meta: { gtm: 'syuttyou-kaitori-kagawa' },
    component: SyuttyouKaitoriKagawa
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/ehime',
    name: 'syuttyou-kaitori-ehime',
    meta: { gtm: 'syuttyou-kaitori-ehime' },
    component: SyuttyouKaitoriEhime
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/kochi',
    name: 'syuttyou-kaitori-kochi',
    meta: { gtm: 'syuttyou-kaitori-kochi' },
    component: SyuttyouKaitorikochi
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/okayama',
    name: 'syuttyou-kaitori-okayama',
    meta: { gtm: 'syuttyou-kaitori-okayama' },
    component: SyuttyouKaitoriOkayama
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/fukuoka',
    name: 'syuttyou-kaitori-fukuoka',
    meta: { gtm: 'syuttyou-kaitori-fukuoka' },
    component: SyuttyouKaitoriFukuoka
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/saga',
    name: 'syuttyou-kaitori-saga',
    meta: { gtm: 'syuttyou-kaitori-saga' },
    component: SyuttyouKaitoriSaga
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/nagasaki',
    name: 'syuttyou-kaitori-nagasaki',
    meta: { gtm: 'syuttyou-kaitori-nagasaki' },
    component: SyuttyouKaitoriNagasaki
  },
  {
    path: '/ec/page/service/syuttyou-kaitori/kumamoto',
    name: 'syuttyou-kaitori-kumamoto',
    meta: { gtm: 'syuttyou-kaitori-kumamoto' },
    component: SyuttyouKaitoriKumamoto
  },
  {
    path: '/ec/page/guide/sitemap',
    name: 'guide-sitemap',
    meta: { gtm: 'guide-sitemap' },
    component: GuideSitemap
  },
  {
    path: '/ec/page/guide/service-guide',
    name: 'guide-service-guide',
    meta: { gtm: 'guide-service-guide' },
    component: GuideServiceGuide
  },
  {
    path: '/ec/page/guide/ask',
    name: 'guide-ask',
    meta: { gtm: 'guide-ask' },
    component: GuideAsk
  },
  {
    path: '/ec/page/guide/kiyaku',
    name: 'guide-kiyaku',
    meta: { gtm: 'guide-kiyaku' },
    component: GuideKiyaku
  },
  {
    path: '/ec/page/service/used-6months-warranty',
    name: 'service-used-6months-warranty',
    meta: { gtm: 'service-used-6months-warranty' },
    component: ServiceUsed6monthsWarranty
  },
  {
    path: '/ec/page/service/used-sp-90days-warranty',
    name: 'service-used-sp-90days-warranty',
    meta: { gtm: 'service-used-sp-90days-warranty' },
    component: ServiceUsedSp90daysWarranty
  },
  {
    path: '/ec/page/service/used-watch-1years-warranty',
    name: 'service-used-watch-1years-warranty',
    meta: { gtm: 'service-used-watch-1years-warranty' },
    component: ServiceUsedWatch1yearsWarranty
  },
  {
    path: '/ec/page/service/sell-guide',
    name: 'service-sell-guide',
    meta: { gtm: 'service-sell-guide' },
    component: ServiceSellGuide
  },
  {
    path: '/ec/page/service/syuttyou-kaitori',
    name: 'service-syuttyou-kaitori',
    meta: { gtm: 'service-syuttyou-kaitori' },
    component: ServiceSyuttyouKaitori
  },
  {
    path: '/ec/page/service/syuttyou-kaitori_ad',
    name: 'service-syuttyou-kaitori_ad',
    meta: { gtm: 'service-syuttyou-kaitori_ad' },
    component: ServiceSyuttyouKaitoriAd
  },
  {
    path: '/ec/page/service/syuttyou-kaitori_ad2',
    name: 'service-syuttyou-kaitori_ad2',
    meta: { gtm: 'service-syuttyou-kaitori_ad2' },
    component: ServiceSyuttyouKaitoriAd2
  },
  {
    path: '/ec/page/service/syuttyou-kaitori_ad3',
    name: 'service-syuttyou-kaitori_ad3',
    meta: { gtm: 'service-syuttyou-kaitori_ad3' },
    component: ServiceSyuttyouKaitoriAd3
  },
  {
    path: '/ec/page/service/syuttyou-kaitori_ad4',
    name: 'service-syuttyou-kaitori_ad4',
    meta: { gtm: 'service-syuttyou-kaitori_ad4' },
    component: ServiceSyuttyouKaitoriAd4
  },

  {
    path: '/ec/page/service/tyokusou-kaitori',
    name: 'service-tyokusou-kaitori',
    meta: { gtm: 'service-tyokusou-kaitori' },
    component: ServiceTyokusouKaitori
  },

  {
    path: '/ec/page/service/tokutoku-kaitori',
    name: 'service-tokutoku-kaitori',
    meta: { gtm: 'service-tokutoku-kaitori' },
    component: ServiceTokutokuKaitori
  },

  {
    path: '/ec/page/service/houjin-kaitori',
    name: 'service-houjin-kaitori',
    meta: { gtm: 'service-houjin-kaitori' },
    component: ServiceHoujinKaitori
  },

  {
    path: '/ec/page/news/20200601',
    name: 'other-news-20200601',
    meta: { gtm: 'news-20200601' },
    component: News20200601
  },

  {
    path: '/ec/page/feature/film-purchase',
    name: 'feature-film-purchase',
    meta: { gtm: 'feature-film-purchase' },
    component: FeatureFilmPurchase
  },

  {
    path: '/ec/page/feature/camera-guide',
    name: 'feature-camera-guide',
    meta: { gtm: 'feature-camera-guide' },
    component: FeatureCameraGuide
  },

  {
    path: '/ec/page/feature/recommended-old-lens',
    name: 'feature-recommended-old-lens',
    meta: { gtm: 'feature-recommended-old-lens' },
    component: FeatureRecommendedOldLens
  },

  {
    path: '/ec/page/feature/tell-me-35mm-film',
    name: 'feature-tell-me-35mm-film',
    meta: { gtm: 'feature-tell-me-35mm-film' },
    component: FeatureTellMe35mmFilm
  },

  {
    path: '/ec/page/feature/tell-me-chuuban',
    name: 'feature-tell-me-chuuban',
    meta: { gtm: 'feature-tell-me-chuuban' },
    component: FeatureTellMeChuuban
  },
  {
    path: '*',
    name: 'not-found-page',
    component: NotFoundPage
  }
];

const scrollBehavior = async (to: any, from: any, savedPosition: any) => {
  if (to.hash) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      const el = document.querySelector(to.hash);
      return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
    }

    return { x: 0, y: 0 };
  }

  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes
});

// vue-gtm設定
Vue.use(VueGtm, {
  // GTM ID設定
  id: 'GTM-T6TZ94D',
  // プラグイン有効
  enabled: true,
  // デバックモードON
  debug: isDebugMode(),
  // GTMスクリプトロード
  loadScript: true,
  // ルーターと同期
  vueRouter: router
});

/**
 * GTMのトラッキングイベントを発火する
 * ルーティング前の発火させる必要がある
 * @param route ルーティング
 */
const _trackEvent = async (route: Route): Promise<void> => {
  const gtmPageName = route.meta.gtm;
  if (gtmPageName) {
    await GtmService.waitInitGtm();
    GtmService.trackEvent(gtmPageName);
  }
};

router.afterEach(async (to, from) => {
  // $storeにrouteLoadedフラグをセット
  Vue.prototype.$store.loader.routeLoaded();
});

export default router;
